import clsx from 'clsx';
import { difference, values } from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { SkeBeta } from '../../../common/components/beta';
import { GridFilterItem, QueryOperators, ResponseWithManyMeta } from '../../../common/interfaces/response-format.model';
import { GetConnectionPayload, getConnections } from '../../../features/connection/ConnectionCRUD';
import {
	ConnectionFilterableFields,
	ConnectionTypeEnumWithCa,
} from '../../../features/connection/interfaces/connection.model';
import { VwConnection, VwConnectionDetails } from '../../../features/connection/interfaces/vw_connection.model';
import { isAiReviewEnabled } from '../../../features/settings/utils/settings.util';
import { teamSlice } from '../../../features/team/team.api';
import { TeamModel } from '../../../features/team/interfaces/team.model';
import { WorkflowStepToken } from '../../../features/workflow/interfaces/workflow.model';
import { RootState } from '../../../setup';
import { Avatar } from '../../components/Avatar';
import { Pagination } from '../../components/Pagination';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { getPagination } from '../../CRUD/TeamCRUD';
import { UserModel } from '../../models';
import { CorrectiveActionModal } from '../../modules/discipline/components/CorrectiveActionModal';
import {
	Discipline,
	DisciplineStatusEnum,
	DisciplineStatusForHumansEnum,
} from '../../modules/discipline/models/Discipline.models';
import { DisciplineType } from '../../types/DisciplineType';
import { SortDateEnum } from '../../types/SortDateEnum';

interface Props {
	className: string;
}

export function RequiredWidget({ className }: Props) {
	const token: string = useSelector<RootState>(({ auth }) => auth.accessToken, shallowEqual) as string;
	const user: UserModel = useSelector<RootState>(({ auth }) => auth.user, shallowEqual) as UserModel;
	const [disciplineData, setDisciplineData] = useState<any>(null);
	const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
	const [teamId, setTeamId] = useState<number>();
	const [showCorrectiveAction, setShowCorrectiveAction] = useState<boolean>(false);
	const btnEditDisciplineRef = useRef<HTMLButtonElement>(null);
	const [viewMode, setViewMode] = useState<'action' | 'awareness'>('action');
	const [totalCorrectiveActions, setTotalCorrectiveActions] = useState<number>();
	const [connectionMeta, setConnectionMeta] = useState<ResponseWithManyMeta<VwConnection<VwConnectionDetails>>>();

	let currentDate = moment().tz('US/Central');
	const DURATION_DANGER_THRESHOLD_MINUTES = 60 * 72;
	const DURATION_WARNING_THRESHOLD_MINUTES = 60 * 48;

	const pageLimit = 10;

	const loadDisciplines = async (opts?: {pageNumber?: number, link?: string}) => {
		const pageParam = (opts?.pageNumber) ? `&page=${opts.pageNumber}` : '';
		const teamFilter = (!!teamId) ? `&teamId=${teamId}` : '';
		const modeParam = `&mode=${viewMode}`;
		const sortParam = `&sort=${SortDateEnum.Desc}`;
		const url = (opts?.link) ? opts.link : `api/disciplines?limit=${pageLimit}${pageParam}${teamFilter}${modeParam}${sortParam}`;
		return getPagination(url, token)
			.then(({ data }) => {
				setDisciplineData(data);
				setTotalCorrectiveActions(data.meta.totalItems);
			})
			.catch(err => {
				console.error(err.response);
			});
	};
	const { data: teams, isSuccess: teamsSuccess } = teamSlice.useGetAllTeamsQuery();
	const loadConnections = async() => {
		const filters: GridFilterItem<ConnectionFilterableFields>[] = [
			{
				field: 'connection_type_array',
				value: [
					ConnectionTypeEnumWithCa.Review,
				],
				operator: QueryOperators.Equals,
			},
			{
				field: 'status_array',
				value: difference(values(WorkflowStepToken), [WorkflowStepToken.Declined, WorkflowStepToken.Filed]),
				operator: QueryOperators.Contains,
			},
		]
		if (teamId) {
			filters.push({
				field: 'team_ids',
				value: [teamId],
				operator: QueryOperators.Contains,

			})
		}
		const flt: GetConnectionPayload = {
			filters,
			page: 1,
			pageSize: 100,
			sort: [{
				field: 'date',
				dir: 'desc',
				position: 0,
			}],
			detailed: true,
		};
		const conns = await getConnections(token, flt);
		setConnectionMeta(conns.data.results.meta);
	}

	const handleViewCorrectiveAction = (index: number) => {
		setShowCorrectiveAction(true);
		setSelectedIndex(index);
		// eliminate race condition causing fatal error
		setTimeout(() => {
			btnEditDisciplineRef.current?.click();
		}, 1);
	};

	const getHumanReadableDuration = (minutesSinceCreation: number) => {
		const duration = moment.duration(minutesSinceCreation, 'minutes');
		const days = Math.floor(duration.asDays());
		const hours = duration.hours();
		const minutes = duration.minutes();

		if (days > 0) {
			return `${days}d ${hours}h`;
		} else if (hours > 0) {
			return `${hours}h ${minutes}m`;
		} else {
			return `${minutes}m`;
		}
	};

	useEffect(() => {
		loadDisciplines();
		loadConnections();
	}, [teamId, token, user, viewMode]);

	return (
		<div className={clsx('card', className)}>
			<div className="card-header border-0 pt-5 d-flex flex-column">
				<div className="d-flex align-items-center flex-row justify-content-between flex-wrap">
					<div className="flex-grow-1 justify-content-center justify-content-md-start">
						<h3 className="d-flex flex-column">
							Action Center
						</h3>
						<div className="d-flex flex-column flex-wrap">
							<span className="">{totalCorrectiveActions} Corrective Actions</span>
							{isAiReviewEnabled(user) && (
								<>
									<NavLink
										to="/connections/review"
									>
										{connectionMeta?.pagination.totalItems} Reviews on Connection Grid
										<SkeBeta />
									</NavLink>
								</>
							)}
						</div>
					</div>
					{teamsSuccess && teams && teams?.length > 1 && (
						<select
							defaultValue={teamId === undefined ? '' : teamId}
							onChange={e => {
								if (e.target.value === '') {
									return setTeamId(undefined);
								}
								setTeamId(+e.target.value);
							}}
							className="form-select form-select-solid w-auto ms-auto">
							<option value="">All teams</option>
							{teams.map((team: TeamModel) => {
								return (
									<option
										key={team.id}
										value={team.id}>
										{team.name}
									</option>
								);
							})}
						</select>
					)}

					<ToggleSwitch
						wrapperClasses="ms-5"
						inactiveValue="awareness"
						activeValue="action"
						activeLabel="Action Mode"
						inactiveLabel="Awareness Mode"
						isChecked={viewMode === 'action'}
						onChange={setViewMode}
					></ToggleSwitch>
				</div>
				<div className="d-flex justify-content-end py-2">
          <span className="fw-light fst-italic">
            {viewMode === 'action' &&
							'Showing items requiring action from you'
						}
						{viewMode === 'awareness' &&
							`Showing items not requiring action from you, but you created or are assigned to review`
						}
          </span>
				</div>
			</div>

			<div>
				{!totalCorrectiveActions &&
					<div className="fw-bold text-center">No Records found</div>
				}
				{!!totalCorrectiveActions && (
					<div className="card-body pt-0 pb-0">
						<div className="table-responsive">
							<table className="table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 table-hover">
								<thead className="rounded-3 bg-light">
								<tr className="fw-bolder text-muted">
									<th className="min-w-150px fs-6 text-center">Employee's Name</th>
									{user.roles?.includes('SuperAdmin') &&
										<th className="min-w-150px fs-6 text-center">Requester's Name</th>
									}
									<th className="min-w-140px fs-6 fs-6">
										Date
										<span className="ms-3">↓</span>
									</th>
									<th className="min-w-140px fs-6">Category</th>
									<th className="min-w-140px fs-6">Detail</th>
									<th className="min-w-150px fs-6 text-center">Time in Queue</th>
									<th className="min-w-120px fs-6">Status</th>
									{/*<th className='min-w-100px text-center'>
								 <i className='bi bi-three-dots fs-5'></i>
								 </th>*/}
								</tr>
								</thead>
								<tbody className="fs-8">
								{
									(disciplineData?.items as DisciplineType[]).map((discipline, i) => {
										const minSinceCreation = moment.duration(currentDate.diff(moment(discipline.created_at).tz('US/Central'))).asMinutes()
										return <tr
											className="cursor-pointer"
											onClick={() => handleViewCorrectiveAction(i)}
											key={discipline.id}>
											<td>
												<div className="d-flex align-items-center ps-5">
													<Avatar
														url={discipline.employee?.avatarUrl}
														alt="employee avatar"
													/>
													<div className="d-flex justify-content-start flex-column">
														{/*<Link className='text-hover-primary' to={`/team-member/${discipline.team?.id}/show/${discipline.employee?.id}`}>*/}
														{discipline.employee?.firstName} {discipline.employee?.lastName}
														{/*</Link>*/}
													</div>
												</div>
											</td>
											{user.roles?.includes('SuperAdmin') &&
												<td>
													<div className="d-flex align-items-center ps-5">
														<Avatar
															url={discipline.creator?.avatarUrl}
															alt="employee avatar"
														/>
														<div className="d-flex justify-content-start flex-column">
															{/*<a href='#' className='text-hover-primary'>*/}
															{discipline.creator?.firstName} {discipline.creator?.lastName}
															{/*</a>*/}
														</div>
													</div>
												</td>
											}
											<td>
												{discipline.date}
											</td>
											<td>
												{discipline.category.name}
											</td>
											<td>
												<div
													className="d-block"
													style={{color: discipline.step.stepColor}}>
													{discipline.step.name}
												</div>
											</td>
											<td>
												<span
													className={clsx("btn d-block",
														{
															'text-danger': minSinceCreation >= DURATION_DANGER_THRESHOLD_MINUTES && discipline.status !== DisciplineStatusEnum.Draft,
															'text-warning': minSinceCreation >= DURATION_WARNING_THRESHOLD_MINUTES && discipline.status !== DisciplineStatusEnum.Draft,
															'text-success': minSinceCreation < DURATION_WARNING_THRESHOLD_MINUTES && discipline.status !== DisciplineStatusEnum.Draft,
															'text-primary': discipline.status === DisciplineStatusEnum.Draft
														})}
												>
													{![
															DisciplineStatusEnum.DisciplineDeclined,
															DisciplineStatusEnum.DocumentationFiled
														].includes(discipline.status) &&
														<>
															{getHumanReadableDuration(minSinceCreation)}
														</>
													}
												</span>
											</td>
											<td className="text-end">
												<div className="d-flex flex-column w-100 me-2">
													<div className="d-flex flex-stack">
													<span
														className={clsx('me-2 badge-light px-3 badge',
															{
																'badge-light': [
																	DisciplineStatusEnum.Draft,
																	DisciplineStatusEnum.IssuedToEmployee,
																	DisciplineStatusEnum.SubmittedToHr,
																	DisciplineStatusEnum.ReceivedByHr,
																	DisciplineStatusEnum.DocumentationFiled,
																	DisciplineStatusEnum.DisciplineDeclined,
																].includes((discipline as unknown as Discipline).status),
																'badge-light-warning text-dark': [
																	DisciplineStatusEnum.RequestedChange,
																	DisciplineStatusEnum.RequestedApproval,
																].includes((discipline as unknown as Discipline).status),
																'badge-light-primary': [DisciplineStatusEnum.Approved].includes((discipline as unknown as Discipline).status),
															})}>
														{DisciplineStatusForHumansEnum[discipline.status]}
													</span>
													</div>
												</div>
											</td>
										</tr>;
									})
								}
								</tbody>
							</table>
						</div>
					</div>
				)}
			</div>


			<div className="card-footer py-2 border-0">
				{disciplineData ? disciplineData.items.length > 0 &&
					<Pagination
						data={disciplineData}
						handlePrevious={() => loadDisciplines({link: disciplineData.links.previous})}
						handleNext={() => loadDisciplines({link: disciplineData.links.next})}
						handlePageIndex={(pageNumber => loadDisciplines({pageNumber}))}
					/> : null}
			</div>

			<button
				className="d-none"
				type="button"
				ref={btnEditDisciplineRef}
				data-bs-toggle="modal"
				data-bs-target="#corrective-action-modal-from-required-widget"
			></button>
			{selectedIndex !== null && disciplineData?.items.length > 0 &&
				<CorrectiveActionModal
					show={showCorrectiveAction}
					disciplineId={disciplineData.items[selectedIndex].id}
					modalId="corrective-action-modal-from-required-widget"
					employee={disciplineData.items[selectedIndex].employee}
					onClose={() => {
						setSelectedIndex(null);
						setShowCorrectiveAction(false);
					}}
					refresh={() => {
						setSelectedIndex(null);
						setShowCorrectiveAction(false);
						loadDisciplines();
					}} />}
		</div>
	);
}
