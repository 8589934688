import React, {ReactNode, useEffect, useState} from 'react';
import {Document, Font, Image, Page, PDFViewer, Styles, StyleSheet, Text, View} from '@react-pdf/renderer';
import {SkeModal} from '../modals/generic/SkeModal';
import {Button, Modal} from 'react-bootstrap';


interface Props {
	show: boolean;
	title: string;
	// style?: {[key:string]: any};
	// signature?: string;
	// documentContents?: ReactNode;
	height?: number;
	width?: number;
	size?: any;
	onClose?: (action: boolean)=> void;
	children?: ReactNode;
}

Font.register({
	family: 'PinyonScript-Regular',
	src: '/static/media/PinyonScript-Regular.8d81e07e5bc82ec02bf7.ttf',
});

export function SkePdf({
												 show,
												 title,
												 height,
												 width,
												 size,
												 onClose,
												 children
											 }: Props){

	const [showModal, setShowModal] = useState(show);

	const handleClose = ()=>{
		if (onClose) {
			onClose(false);
		}
	}

	useEffect(() => {
		setShowModal(show);
	}, [show]);

	return(
		<SkeModal
			title={title}
			show={show}
			size='lg'
			onClose={handleClose}
			footerContents={
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
				</Modal.Footer>
			}
		>
			<PDFViewer
			 height={height}
			 width={width}
			>
				<Document>
					{children}
				</Document>
			</PDFViewer>
		</SkeModal>
	)
}